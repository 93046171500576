import React from "react"
import { graphql } from "gatsby"

import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, excerpt } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} keywords={[`paris`]} description={excerpt} />
      <article key={frontmatter.title}>
        <header>
          <h1>{frontmatter.title}</h1>
          <p>posted on <time dateTime="{frontmatter.date}">{frontmatter.date}</time> by {frontmatter.author}</p>
        </header>
          <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        path
        title
        type
        date(formatString: "MMMM DD, YYYY")
        author
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
            }
          }
        }
        alt
      }
    }
  }
`
